import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@apmg/mimas';
import Link from 'next/link';

const CollectionContributors = (props) => {
  return (
    <div className="collection-contribs">
      <h2>Contributors</h2>
      <ul>
        {props.contributors.map((contrib, index) => {
          return (
            <li key={index}>
              <Link href={`/people/${contrib.profile?.canonicalSlug}`}>
                {contrib.profile.primaryVisuals.thumbnail && (
                  <Image
                    image={contrib.profile.primaryVisuals.thumbnail}
                    aspectRatio="square"
                    alt={contrib.profile.primaryVisuals.thumbnail.shortCaption}
                    sizes="50px"
                  />
                )}
                {contrib.profile.firstName} {contrib.profile.lastName}
              </Link>
              {Boolean(contrib.profile.profileRelatedLinks.length) && (
                <span className="collection-contrib-addl-links">
                  (
                  {contrib.profile.profileRelatedLinks.map((link, i) => (
                    <Link href={link.uri} key={link.uri}>
                      {link.text}
                      {i + 1 < contrib.profile.profileRelatedLinks.length &&
                        ', '}
                    </Link>
                  ))}
                  )
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

CollectionContributors.propTypes = {
  contributors: PropTypes.array,
};

export default CollectionContributors;
