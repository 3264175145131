import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/compat/router';
import Link from 'next/link';

const htmlStringToElement = (html) => {
  if (typeof document == 'undefined') return false;
  let template = document.createElement('div');
  template.innerHTML = html;
  return template;
};

const ampStyles = {
  link: {
    color: '#00334e',
    textDecoration: 'none',
  },
};

const hashCode = function (s) {
  var hash = 5381,
    i = s.length;

  while (i) {
    hash = (hash * 33) ^ s.charCodeAt(--i);
  }
  return hash >>> 0;
};

const ApmCustomHtmlOverride = ({ nodeData, minimal, isAmp }) => {
  const [html, setHtml] = useState('');
  const router = useRouter();
  const ANY_SCRIPT = /<script[\s\S]*?>[\s\S]*?<\/script>/gi;

  if (minimal) {
    return null;
  }
  if (isAmp) {
    return (
      <Link style={ampStyles.link} href={nodeData.attrs.fallback_url}>
        {nodeData.attrs.fallback_text}
      </Link>
    );
  }

  const injectScript = (node, scrpt, id) => {
    let tag = document.createElement('script');
    // if (document.getElementById(id)) return;
    for (const key in scrpt.dataset) {
      const curKey = key.replace(
        /[A-Z]/g,
        (match, offset) => (offset > 0 ? '-' : '') + match.toLowerCase()
      );
      tag.setAttribute(`data-${curKey}`, scrpt.dataset[key]);
    }
    tag.type = 'text/javascript';
    tag.defer = true;

    if (scrpt.src) {
      tag.src = scrpt.src;
    } else {
      tag.innerHTML = scrpt.innerHTML;
    }
    tag.id = id;
    node.appendChild(tag);
  };

  useEffect(() => {
    const dirtyHtml = nodeData.attrs.html;
    const ele = htmlStringToElement(dirtyHtml);
    const scriptsToInject = Array.from(ele.querySelectorAll('script'));
    const localScripts = JSON.parse(localStorage.getItem('localScripts')) || [];
    scriptsToInject.forEach((scrpt) => {
      const id = `__id__${hashCode(scrpt.innerHTML)}`;
      injectScript(document.body, scrpt, id);
      if (localScripts.indexOf(id) === -1) {
        // only add it if its not already there
        localScripts.push(id);
        localStorage.setItem('localScripts', JSON.stringify(localScripts));
      }
    });
    setHtml(ele.innerHTML.replace(ANY_SCRIPT, ''));

    router.events.on('routeChangeStart', () => {
      const scriptInstanceIds = localStorage.getItem('localScripts');
      let parsedScriptInstanceIds;
      let ele;
      if (scriptInstanceIds) {
        parsedScriptInstanceIds = JSON.parse(scriptInstanceIds);
        parsedScriptInstanceIds.forEach((scriptInstanceId) => {
          ele = document.getElementById(scriptInstanceId);
          if (ele) {
            ele.remove();
          }
        });
        localStorage.removeItem('localScripts');
      }
    });
  }, [nodeData]);

  const markup = { __html: html };
  return (
    <>
      <div className="customHtml" dangerouslySetInnerHTML={markup} />
    </>
  );
};

ApmCustomHtmlOverride.propTypes = {
  nodeData: PropTypes.object,
  minimal: PropTypes.bool,
  isAmp: PropTypes.bool,
};

export default ApmCustomHtmlOverride;
