import React, { useEffect, useRef, useContext } from 'react';
import Head from 'next/head';
import { Pagination } from '@apmg/titan';
import { Body } from '@apmg/amat';
import PropTypes from 'prop-types';
import FullTeaser from '../../components/FullTeaser/FullTeaser';
import Metatags from '../../components/Metatags/Metatags';
import { fishForSocialMediaImage } from '../../components/Metatags/MetaTagHelpers';
import IconChevron from '../../components/Icons/IconChevron';
import ApmRelatedLinkListItemOverride from '../../components/AmatOverrides/ApmRelatedLinkListItemOverride';
import ApmRelatedLinkOverride from '../../components/AmatOverrides/ApmRelatedLinkOverride';
import LinkOverride from '../../components/AmatOverrides/LinkOverride';
import ApmCustomHtmlOverride from '../../components/AmatOverrides/ApmCustomHtmlOverride';
import podcasts from '../../config/podcasts.json';
import OpenableList from '../../components/OpenableList/OpenableList';
import CollectionContributors from './CollectionContributors';
import AdCategoriesContext from 'context/AdCategoriesContext';
import SocialVideoLink from 'components/SocialVideoLink/SocialVideoLink';

const Collection = ({ data: { collection }, withinPage }) => {
  const context = useContext(AdCategoriesContext);
  const podcast = podcasts.podcasts.find(
    (pcast) => pcast.slug == collection.canonicalSlug
  );
  const img = fishForSocialMediaImage(collection);
  // const displayableImage = collection?.primaryVisuals?.lead;

  const contentTopicCollectionRef = useRef(null);
  let checkCollectionName = `${collection?.title}`;

  if (!checkCollectionName) {
    checkCollectionName = 'default';
  }

  const noFollowIndex = collection.results.currentPage !== 1 && true;

  useEffect(() => {
    context.setPrimaryCategory(collection.canonicalSlug);
    context.setAdCategories([collection.canonicalSlug]);
    if (contentTopicCollectionRef) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'sendUWContentTopic',
        contentTopic: checkCollectionName,
      });
    } else {
      console.error('you broke the ads');
    }
  }, [collection.canonicalSlug]);

  return (
    <>
      <Head>
        <link href="/css/pages/collection.css" rel="stylesheet" />
        <link href="/css/components/apmGallerySlideshow.css" rel="stylesheet" />
      </Head>
      {!withinPage && (
        <Metatags
          title={collection.title}
          fullSlug={collection.canonicalSlug}
          description={collection.descriptionText}
          image={img?.url}
          imageHeight={img?.height}
          imageWidth={img?.width}
          imageAlt={collection?.primaryVisuals?.social?.shortCaption}
          topic={collection?.title}
          contentType="website"
          rssUrl={collection.rssUrl}
          originalSourceUrl={collection.originalSourceUrl}
          noFollow={noFollowIndex}
        />
      )}
      <section
        className="page-purpose"
        data-mpr-content-topic={collection.title}
        ref={contentTopicCollectionRef}
      >
        <div className="collection collection-1col">
          {collection?.body && !withinPage && (
            <div className="collection-body userContent">
              <Body
                nodeData={JSON.parse(collection.body)}
                embedded={collection.embeddedAssets}
                overrides={{
                  link: LinkOverride,
                  apm_related_link: ApmRelatedLinkOverride,
                  apm_related_link_list_item: ApmRelatedLinkListItemOverride,
                  apm_custom_html: ApmCustomHtmlOverride,
                }}
              />
              {podcast && <OpenableList links={podcast.links} />}
            </div>
          )}
          {collection?.contributors?.length && !withinPage ? (
            <aside>
              <CollectionContributors contributors={collection.contributors} />
            </aside>
          ) : null}
          <div className="collection_items">
            {collection.results.items.map((item) => {
              let isNewspartners = false;
              if (collection.canonicalSlug === 'newspartners') {
                isNewspartners = true;
              }

              return (
                <>
                  <FullTeaser
                    item={item}
                    newspartners={isNewspartners}
                    key={item.id}
                  />
                  {item.destination && (
                    <SocialVideoLink videoUrl={item.destination} />
                  )}
                </>
              );
            })}
          </div>
          <div className="collection-pagination">
            <div className="pagination_container type-sm">
              <Pagination
                hasFirstAndLast={true}
                inclusiveFirstLast={false}
                buffer={1}
                hrefPrefix={`[...slug]`}
                asPrefix={`${collection?.canonicalSlug}`}
                currentPage={collection.results.currentPage}
                totalPages={collection.results.totalPages}
                middleSymbol="of"
                firstSymbol={collection.results.currentPage}
                nextSymbol={
                  <>
                    <span>Next</span>
                    <IconChevron
                      direction="right"
                      color="currentColor"
                      decorative
                    />
                  </>
                }
                prevSymbol={
                  <>
                    <IconChevron
                      direction="left"
                      color="currentColor"
                      decorative
                    />
                    <span aria-label="Previous">Prev</span>
                  </>
                }
                lastSymbol={collection.results.totalPages}
                prevNextClass="btn btn-primary btn-pagination no-text-transform"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Collection.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
  }),
  withinPage: PropTypes.bool,
};

export default Collection;
