import { FC } from 'react';
import Link from 'next/link';
import Head from 'next/head';

interface Props {
  title?: string;
  links: Array<Record<string, string | undefined>>;
}

const OpenableList: FC<Props> = ({ title = 'Subscribe to Podcast', links }) => {
  return (
    <>
      <Head>
        <link href="/css/components/openableList.css" rel="stylesheet" />
      </Head>
      <details className="openable-list">
        <summary>{title}</summary>
        <ul>
          {links.map((lnk) => {
            return (
              Object.values(lnk)[0] && (
                <li key={Object.keys(lnk)[0]}>
                  <Link
                    href={
                      Object.values(lnk)[0] ||
                      /* this should never actually be reached, it's just to satisfy TS: */ '/'
                    }
                  >
                    {Object.keys(lnk)[0]}
                  </Link>
                </li>
              )
            );
          })}
        </ul>
      </details>
    </>
  );
};

export default OpenableList;
