import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { globals } from '../../config/globals';
import { Time, Heading } from '@apmg/titan';
import { Image } from '@apmg/mimas';
import { linkByTypeHref, linkByTypeAs, analyzeUrl } from '../../utils/cjsutils';
import { audioDownloadPrefix } from '../../utils/utils';
import AudioPlayButton from '../AudioPlayButton/AudioPlayButton';
import Contributors from 'components/Contributors/Contributors';
import IconTriangle from '../Icons/IconTriangle';
import { getVideoEmbedUrl } from '../../utils/utils';
import AddFavoriteWrapper from 'components/Data/AddFavoriteWrapper';
import Head from 'next/head';

const FullTeaser = ({
  item,
  size,
  newspartners,
  loading,
  condensedBtns,
  hideAddFavorites,
  headingLvl = 2,
  saveButtonBG,
}) => {
  let link = linkByTypeHref(item);
  let linkAs = linkByTypeAs(item);

  if (newspartners) {
    link = link.replace(/story/, 'newspartners/story');
    linkAs = linkAs.replace(/story/, 'newspartners/story');
  }

  const imageSizes = size === 'condensed' ? '120px' : globals.sizes.collection;

  const imageData = item.primaryVisuals.normal
    ? item.primaryVisuals.normal
    : item.primaryVisuals.thumbnail;

  return (
    <div className="teaserContainer">
      <Head>
        <link href="/css/components/fullTeaser.css" rel="stylesheet" />
      </Head>
      <article className="teaser">
        {item.primaryVisuals.video ? (
          <div className="teaser-primary-visual">
            <iframe
              allowFullScreen
              title={item.primaryVisuals.video.url}
              src={getVideoEmbedUrl(item.primaryVisuals.video.url)}
            ></iframe>
          </div>
        ) : imageData ? (
          <div className="teaser-primary-visual">
            <Link passHref data-testid="relativeLink" href={linkByTypeAs(item)}>
              <Image
                image={imageData}
                elementclassName="content_thumbnail"
                aspectRatio="normal"
                sizes={imageSizes}
                alt={item.title}
                loading={loading}
                /* Accessibility: Linked images should describe their destination */
              />
            </Link>
          </div>
        ) : (
          ''
        )}
        <div className="teaser_content">
          <div className="teaser_meta type-sm">
            <div className="date">
              {item.publishDate && (
                <Time
                  elementclassName="teaser_time"
                  dateTime={item.publishDate}
                  formatString="MMMM D, YYYY h:mm A"
                />
              )}
            </div>
          </div>
          <div className="teaser_header">
            <Link passHref data-testid="relativeLink" href={linkByTypeAs(item)}>
              <Heading level={headingLvl} className="hdg hdg-2">
                {item.title}
              </Heading>
            </Link>
          </div>
          <div className="teaser_body userContent">{item.descriptionText}</div>
          {item.collectionRelatedLinks?.length > 0 && (
            <ul className="related related-teaser">
              {item.collectionRelatedLinks.map((link) => {
                const { as } = analyzeUrl(link.url);
                return (
                  <li
                    className="related_item"
                    key={`${link.url}${link.title}${link.prefix}`}
                  >
                    <span className="related_prefix">{link.prefix}</span>
                    <Link href={as || link.url} className="related_link">
                      {link.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
          {item.contributors?.length > 0 && (
            <div className="teaser_contributors type-sm">
              <IconTriangle
                color="var(--color-mpr-button-blue)"
                width="0.75rem"
              />
              by <Contributors authors={item.contributors} />
            </div>
          )}
          <div className="teaser-controls">
            <div className="teaser_button">
              {item.audio && item.audio[0]?.encodings[0]?.playFilePath && (
                <AudioPlayButton
                  label="Listen"
                  audioTitle={item.title}
                  audioSource={audioDownloadPrefix(
                    item.audio[0].encodings[0].playFilePath
                  )}
                  audioThumbnailSrc={
                    item.primaryVisuals.thumbnail?.aspect_ratios?.square
                      ?.instances[0]
                  }
                  audioThumbnailAlt={
                    item.primaryVisuals.thumbnail?.shortCaption || item.title
                  }
                  showDuration={`${
                    item.audio[0]?.encodings[0]?.durationHms ?? ''
                  }`}
                  playBtnSm={condensedBtns}
                />
              )}
            </div>
            {!hideAddFavorites && (
              <div className="teaser_save">
                <AddFavoriteWrapper
                  title={item.title}
                  contentArea="mprnews"
                  resourceType={item.resourceType}
                  id={item.id}
                  slug={item.canonicalSlug}
                  showBackground={saveButtonBG}
                />
              </div>
            )}
          </div>
        </div>
      </article>
    </div>
  );
};

FullTeaser.propTypes = {
  // TODO: define item object proptypes better
  loading: PropTypes.string,
  item: PropTypes.object,
  newspartners: PropTypes.bool,
  contributors: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
  size: PropTypes.string,
  hideVideoCaption: PropTypes.bool,
  condensedBtns: PropTypes.bool,
  hideAddFavorites: PropTypes.bool,
  headingLvl: PropTypes.number,
  saveButtonBG: PropTypes.bool,
};

export default FullTeaser;
