import React, { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
  color?: string /* Default is currentColor, so do not set unless you want a different color than accompanying text. */;
  decorative?: boolean;
}

/* This icon has no title or label because it is purely decorative, so it should NOT be announced by screen readers. */

const IconTriangle: FC<Props> = ({
  height = '1rem',
  width = '1rem',
  color = 'currentColor',
  decorative = true,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={decorative}
    >
      <polygon points="0 0 16 16 0 16 0 0" fill={color} />
    </svg>
  );
};

export default IconTriangle;
