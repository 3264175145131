import React, { FunctionComponent } from 'react';
import ContributorType from 'models/Contributors/ContributorType';
import { Link } from '@apmg/titan';

interface Props {
  items: ContributorType[];
}

const ContributorsLink: FunctionComponent<Props> = (props) => {
  const { items } = props;

  return (
    <span>
      {items.map((item, i) => {
        if (!item.profile) {
          return null;
        }
        if (i === items.length - 1) {
          return (
            <Link
              key={item.profile.canonicalSlug}
              href={`/people/${item.profile.canonicalSlug}`}
            >
              {item.profile.title}
            </Link>
          );
        } else if (i === items.length - 2) {
          return (
            <span key={item.profile.canonicalSlug}>
              <Link href={`/people/${item.profile.canonicalSlug}`}>
                {item.profile.title}
              </Link>
              {''} and {''}
            </span>
          );
        }
        return (
          <span key={item.profile.canonicalSlug}>
            <Link href={`/people/${item.profile.canonicalSlug}`}>
              {item.profile.title}
            </Link>
            , {''}
          </span>
        );
      })}
    </span>
  );
};

export default ContributorsLink;
