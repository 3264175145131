'use client';
import React, { FC, useState, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Head from 'next/head';

interface Props {
  isSideBar?: boolean;
}

const NewsLetter: FC<Props> = ({ isSideBar }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return (
    mounted && (
      <>
        <Head>
          <link href="/css/components/newsLetter.css" rel="stylesheet" />
        </Head>
        <div
          className={`newsletter ${isSideBar ? 'newsletter-sidebar' : ''}`}
          data-testid={isSideBar && 'newsletter-sidebar'}
        >
          {isSideBar && (
            <div className="newsletter-img-container">
              <Image
                src="/img/news-letter.png"
                alt="Woman using a tablet"
                height={359}
                width={525}
              />
              <div className="newsletter-icon">
                <img
                  src="/img/icon-send.svg"
                  alt=""
                  /* decorative */
                  data-testid="icon-newsletter"
                />
              </div>
            </div>
          )}

          <div className="newsletter-inner">
            <div className="newsletter-heading">
              <div className="newsletter-heading-desktop">
                {!isSideBar && (
                  <div className="newsletter-icon">
                    <img
                      src="/img/icon-send.svg"
                      alt=""
                      /* decorative */
                      data-testid="icon-newsletter"
                    />
                  </div>
                )}
              </div>
              <div className="newsletter-heading-mobile">
                {isSideBar && (
                  <div className="newsletter-icon">
                    <img
                      src="/img/icon-send.svg"
                      alt=""
                      /* decorative */
                      data-testid="icon-newsletter"
                    />
                  </div>
                )}
              </div>
              <div className="newsletter-heading-text">
                <h2 className="hdg-3">News you can use in your inbox</h2>
              </div>
            </div>
            {isSideBar && (
              <p data-testid="paragraph">
                When it comes to staying informed in Minnesota, our newsletters
                overdeliver. Sign-up now for headlines, breaking news, hometown
                stories, weather and much more. Delivered weekday mornings.
              </p>
            )}

            <form
              action="https://mcpostman.publicradio.org/subscription_requests"
              className="mcpostman-form"
              method="post"
            >
              <input
                name="subscription_request[property_key]"
                type="hidden"
                value="02e349c2-215b-45d8-829d-146536cb3c73"
              />
              <input
                name="subscription_request[notification_list]"
                type="hidden"
                value="c03b2798-a776-4140-91bd-c3054d1bde55"
              />
              <input
                name="subscription_request[sde_external_key]"
                type="hidden"
                value=""
              />
              <input
                name="subscription_request[fsm][Form_BusinessUnit]"
                type="hidden"
                value="MPR"
              />
              <input
                name="subscription_request[fsm][Form_FormName]"
                type="hidden"
                value="c2575faf-cd29-4891-ad98-7010f3a0ad0b"
              />
              <input
                name="subscription_request[fsm][Form_Opt_In_Source]"
                type="hidden"
                value=""
              />
              <input
                type="hidden"
                name="subscription_request[publication_lists][529]"
                value="on"
              />

              <div>
                <input
                  type="email"
                  name="subscription_request[fsm][Form_Email_Address]"
                  id="subscription_request_fsm_Form_Email_Address"
                  placeholder="Enter your email"
                  maxLength={254}
                  className="form-control"
                  required
                />
              </div>
              <button
                id="submitButton"
                type="submit"
                value="Subscribe"
                className="btn btn-primary btn-full-width"
              >
                Subscribe
              </button>
            </form>
            {isSideBar && (
              <div className="newsletter-link">
                <Link
                  className="link"
                  href="https://www.americanpublicmedia.org/terms"
                  data-testid="termslink"
                  target="_blank"
                >
                  Terms
                </Link>
              </div>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default NewsLetter;
