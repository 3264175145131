import React, { FunctionComponent } from 'react';
import ContributorsLink from './ContributorsLink';
import ContributorType from 'models/Contributors/ContributorType';

interface Props {
  errorCode?: number;
  authors: ContributorType[];
}

const Contributors: FunctionComponent<Props> = (props) => {
  if (!props || !props.authors) return null;

  const sortByOrder = (authors: ContributorType[]) => {
    if (authors.length && authors[0].order) {
      authors.sort((a: ContributorType, b: ContributorType) => {
        if (a.order && b.order) {
          return Number(a.order) - Number(b.order);
        }
        return -1;
      });
    }
    return authors;
  };

  const sortedAuthors = sortByOrder(props.authors);

  return <>{sortedAuthors && <ContributorsLink items={sortedAuthors} />}</>;
};

export default Contributors;
