import React, { FC } from 'react';
import { Link } from '@apmg/titan';
import AdvertisementBanner from '../Advertisement/AdvertisementBanner';
import { globals } from '../../config/globals';
import NewsLetterSideBar from 'components/NewsLetterSideBar/NewsLetterSideBar';

interface Props {
  homepageTopic?: string;
  pageTopic: string;
  newsletter?: boolean;
  CustomContent?: JSX.Element;
}

const Sidebar: FC<Props> = ({ newsletter = true, CustomContent }) => {
  //const programDate = dayjs().format('ddd');

  const adInsert = {
    type: 'adinsert',
    id: `mpr-ad-2`,
    sizes: [[300, 250]],
    sizeMap: { 0: [300, 250] },
    cmsId: 'sidebar_ad',
    primaryCollection: 'sidebar_ad',
    collections: ['sidebar_ad'],
    position: 'mr_bottom',
    isSidebar: true,
  };

  return (
    <div className="sidebar">
      {newsletter && (
        <div className="section-sm">
          <NewsLetterSideBar isSideBar />
        </div>
      )}
      {CustomContent ? CustomContent : ''}
      <div>
        <div className="section section-sm">
          <div className="hList">
            <Link href={`/schedule`} className="link link-plain">
              Program Schedule
            </Link>
            <Link
              href={`https://www.mpr.org/listen/stations`}
              className="link link-plain"
            >
              Station Directory
            </Link>
          </div>
        </div>
        <div className="section-sm">
          <AdvertisementBanner {...adInsert} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
