import { FC, useEffect, useState } from 'react';

interface Props {
  videoUrl: string;
}

interface Data {
  provider_url: string;
  version: string;
  title: string;
  author_name: string;
  height: number;
  thumbnail_width: number;
  width: number;
  html: string;
  author_url: string;
  url: string;
  provider_name: string;
  thumbnail_url: string;
  type: string;
  thumbnail_height: number;
}

const SocialVideoLink: FC<Props> = ({ videoUrl }) => {
  const [data, setData] = useState<Data | null>(null);

  useEffect(() => {
    fetch(`/api/video/${videoUrl}`)
      .then((response) => response.json())
      .then((data: Data) => setData(data))
      .catch((error) => console.error('Error:', error));
  }, [videoUrl]);

  const markup = { __html: data?.html || '' };

  return (
    <div>
      {markup && (
        <div className="customHtml" dangerouslySetInnerHTML={markup} />
      )}
    </div>
  );
};

export default SocialVideoLink;
